
<script lang="ts">
import { defineComponent, ref, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import Generic from '~/layouts/generic.vue'

export default defineComponent({
  setup() {
    const route = useRoute();
    const iframeSrc = ref('');

    onMounted(() => {
      // Dynamically set the iframe source based on route query params or defaults
      iframeSrc.value = route.query.src?.toString() || '';
    });

    return { iframeSrc };
  }
});
</script>

<template>
  <Generic>
    <iframe :src="iframeSrc" frameborder="0" style="width:100%; height:100vh;">
      <!-- Content inside iframe, if needed -->
    </iframe>
  </Generic>
</template>